import React, { useEffect, useState } from "react"
import { DateTime } from "luxon"
import TimeAgo from "timeago-react"

export const DelayedTimeAgo = ({ timestamp }) => {
  const [loaded, setLoaded] = useState(false)
  const date = DateTime.fromISO(timestamp, { zone: "Europe/London" })

  useEffect(() => setLoaded(true), [])

  if (!loaded) return `${date.toFormat("EEEE d MMM, HH:MM")} UK time`

  return <TimeAgo datetime={date.toJSDate()} />
}
