import timestamp from "../api-data/timestamp.json"
import { DateTime } from "luxon"

export const apiOutdated = async () => {
  if (!timestamp?.websiteTimestamp) return true

  const cacheTimestamp = DateTime.fromISO(timestamp.websiteTimestamp)
  const response = await window.fetch(
    `${process.env.GATSBY_API_ROOT}/timestamp`,
  )
  const currentTimestamp = DateTime.fromISO(
    (await response.json()).websiteTimestamp,
  )

  return (
    currentTimestamp.diff(cacheTimestamp, "seconds").seconds > 0 &&
    DateTime.local().diff(currentTimestamp, "seconds").seconds > 0
  )
}

export const apiRequest = async (path, loadingCallback, dataCallback) => {
  const isOutdated = await apiOutdated()
  if (!isOutdated) return

  loadingCallback(true)

  const response = await window.fetch(`${process.env.GATSBY_API_ROOT}${path}`)

  dataCallback(await response.json())
  loadingCallback(false)
}
